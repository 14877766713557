import React from "react";
import { Link, graphql } from "gatsby";

import { ArticleCard } from "../components/styled";

import { Bio, Layout, SEO } from "../components";

interface IProps {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          excerpt: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            date: string;
            title: string;
            description: string;
          };
        };
      }>;
    };
    allPageViews: {
      nodes: Array<{ id: string; totalCount: number }>;
    };
  };
}

const PopularPosts: React.FC<IProps> = ({ data }) => {
  const pageViews = data.allPageViews.nodes;
  const posts = data.allMarkdownRemark.edges.map((post) => {
    const { slug } = post.node.fields;
    const currentPageViews = pageViews.find(
      (filteredPageViews) => filteredPageViews.id === slug
    )?.totalCount;
    return {
      ...post,
      pageViews: currentPageViews || 0,
    };
  });

  return (
    <Layout>
      <div>
        <SEO
          title="Most popular articles"
          keywords={[
            `blog`,
            `web development`,
            `javascript`,
            `react`,
            `working remote`,
          ]}
        />
        <Bio />
        <h1>Most popular Articles</h1>
        {posts
          .sort((postA, postB) => postB.pageViews - postA.pageViews)
          .map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <ArticleCard key={node.fields.slug}>
                <small>{node.frontmatter.date}</small>
                <h3>
                  <Link to={node.fields.slug}>{title}</Link>
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </ArticleCard>
            );
          })}
      </div>
    </Layout>
  );
};

export default PopularPosts;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    allPageViews(sort: { order: DESC, fields: totalCount }) {
      nodes {
        id
        totalCount
      }
    }
  }
`;
